import { reactive, toRefs, defineAsyncComponent, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/bmsdata/";
import { useMessage } from "@/hooks/web/useMessage";
import { Search, Plus } from "@element-plus/icons-vue";
export default {
  name: "device",
  components: {
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    rule: defineAsyncComponent(() => import('./components/rule.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    RuleFormDialog: defineAsyncComponent(() => import('@/components/MpttFormDialog.vue')),
    ModuleBmsCly: defineAsyncComponent(() => import('./components/ModuleBmsCly.vue'))
  },
  setup() {
    const {
      state
    } = useStore();
    const message = useMessage();
    const refData = reactive({
      ruleFormDialogRefs: null,
      ruleRefs: null,
      loading: false,
      total: 0,
      schoolInfo: {
        searchValue: '',
        activeValue: '',
        id: null,
        schoolId: null
      },
      current: 1,
      formVisible: false,
      pageSize: 100,
      searchForm: {
        objectid: null,
        mdtid: null,
        holdid: ''
      },
      tableData: [],
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '电池编号',
        align: 'center',
        key: 'mdtid',
        visible: true
      }, {
        title: '所属单位',
        align: 'center',
        key: 'holdname',
        visible: true
      }, {
        title: '刷新时间',
        align: 'center',
        key: 'recvtime',
        visible: true
      }, {
        title: '操作',
        align: 'center',
        key: 'active',
        width: 200,
        slots: {
          customRender: 'active'
        },
        visible: true
      }]
    });
    const topOrgId = computed(() => state.treeData.length ? state.treeData[1][0].id : '');
    watch(() => topOrgId.value, val => {
      if (val) {
        nextTick(() => {
          refData.searchForm.holdid = val;
          debounceOnSearch();
        });
      }
    }, {
      immediate: true
    });
    //查询
    const onSearch = async () => {
      refData.loading = true;
      try {
        refData.loading = false;
        const params = {
          pageNo: refData.current,
          pageSize: refData.pageSize,
          ...refData.searchForm
        };
        const {
          data,
          code,
          msg
        } = await API.getBmsDataPage(params);
        if (code == 0) {
          refData.tableData = data.list;
          refData.total = data.total;
        } else {
          refData.tableData = [];
          refData.total = 0;
          message.warning(msg);
        }
      } catch (error) {
        refData.loading = false;
      }
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200); //防抖
    //分页
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //组织树回调
    const handleClick = data => {
      if (data.type == 'school') {
        refData.searchForm.holdid = data.parentId;
        refData.schoolInfo.searchValue = data.name;
        refData.schoolInfo.schoolId = data.id;
      } else {
        refData.searchForm.holdid = data.id;
      }
      refData.schoolInfo.activeValue = data.typeId;
      debounceOnSearch();
    };
    //规则
    const handleRule = (id, hasRule) => {
      refData.ruleRefs.deviceId = id;
      refData.ruleRefs.hasRule = hasRule;
      refData.ruleRefs.visible = true;
    };
    //修改-新增回调
    const handleSuccess = id => {
      debounceOnSearch();
      refData.ruleRefs.visible = id ? true : false;
      refData.ruleRefs.deviceId = id;
      refData.ruleRefs.hasRule = id ? 0 : 1;
      refData.formVisible = false;
    };
    //修改-新增
    const handleEditAdd = (onoff, id) => {
      refData.schoolInfo.id = onoff ? id : null;
      refData.formVisible = true;
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleRule,
      handleEditAdd,
      handleSuccess,
      Search,
      Plus
    };
  }
};